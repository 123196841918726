"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfChoiceStay = instanceOfChoiceStay;
exports.ChoiceStayFromJSON = ChoiceStayFromJSON;
exports.ChoiceStayFromJSONTyped = ChoiceStayFromJSONTyped;
exports.ChoiceStayToJSON = ChoiceStayToJSON;
var ChoiceStayType_1 = require("./ChoiceStayType");
var Stay_1 = require("./Stay");
/**
 * Check if a given object implements the ChoiceStay interface.
 */
function instanceOfChoiceStay(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "choices" in value;
    return isInstance;
}
function ChoiceStayFromJSON(json) {
    return ChoiceStayFromJSONTyped(json, false);
}
function ChoiceStayFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, ChoiceStayType_1.ChoiceStayTypeFromJSON)(json['type']),
        'choices': (json['choices'].map(Stay_1.StayFromJSON)),
    };
}
function ChoiceStayToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, ChoiceStayType_1.ChoiceStayTypeToJSON)(value.type),
        'choices': (value.choices.map(Stay_1.StayToJSON)),
    };
}
