"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Co2eStayMethodology = void 0;
exports.Co2eStayMethodologyFromJSON = Co2eStayMethodologyFromJSON;
exports.Co2eStayMethodologyFromJSONTyped = Co2eStayMethodologyFromJSONTyped;
exports.Co2eStayMethodologyToJSON = Co2eStayMethodologyToJSON;
/**
 *
 * @export
 */
exports.Co2eStayMethodology = {
    Defra: 'defra'
};
function Co2eStayMethodologyFromJSON(json) {
    return Co2eStayMethodologyFromJSONTyped(json, false);
}
function Co2eStayMethodologyFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function Co2eStayMethodologyToJSON(value) {
    return value;
}
