"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTravelProviderPriceStrategies = instanceOfTravelProviderPriceStrategies;
exports.TravelProviderPriceStrategiesFromJSON = TravelProviderPriceStrategiesFromJSON;
exports.TravelProviderPriceStrategiesFromJSONTyped = TravelProviderPriceStrategiesFromJSONTyped;
exports.TravelProviderPriceStrategiesToJSON = TravelProviderPriceStrategiesToJSON;
var runtime_1 = require("../runtime");
var DuffelFlightPriceStrategies_1 = require("./DuffelFlightPriceStrategies");
var TrainlinePriceStrategies_1 = require("./TrainlinePriceStrategies");
/**
 * Check if a given object implements the TravelProviderPriceStrategies interface.
 */
function instanceOfTravelProviderPriceStrategies(value) {
    var isInstance = true;
    return isInstance;
}
function TravelProviderPriceStrategiesFromJSON(json) {
    return TravelProviderPriceStrategiesFromJSONTyped(json, false);
}
function TravelProviderPriceStrategiesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'trainline': !(0, runtime_1.exists)(json, 'trainline') ? undefined : (0, TrainlinePriceStrategies_1.TrainlinePriceStrategiesFromJSON)(json['trainline']),
        'duffel': !(0, runtime_1.exists)(json, 'duffel') ? undefined : (0, DuffelFlightPriceStrategies_1.DuffelFlightPriceStrategiesFromJSON)(json['duffel']),
    };
}
function TravelProviderPriceStrategiesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'trainline': (0, TrainlinePriceStrategies_1.TrainlinePriceStrategiesToJSON)(value.trainline),
        'duffel': (0, DuffelFlightPriceStrategies_1.DuffelFlightPriceStrategiesToJSON)(value.duffel),
    };
}
