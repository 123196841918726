"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfBuildingData = instanceOfBuildingData;
exports.BuildingDataFromJSON = BuildingDataFromJSON;
exports.BuildingDataFromJSONTyped = BuildingDataFromJSONTyped;
exports.BuildingDataToJSON = BuildingDataToJSON;
var runtime_1 = require("../runtime");
var LatLng_1 = require("./LatLng");
/**
 * Check if a given object implements the BuildingData interface.
 */
function instanceOfBuildingData(value) {
    var isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "photos" in value;
    return isInstance;
}
function BuildingDataFromJSON(json) {
    return BuildingDataFromJSONTyped(json, false);
}
function BuildingDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'brand': !(0, runtime_1.exists)(json, 'brand') ? undefined : json['brand'],
        'photos': json['photos'],
        'latlng': !(0, runtime_1.exists)(json, 'latlng') ? undefined : (0, LatLng_1.LatLngFromJSON)(json['latlng']),
        'keyCollection': !(0, runtime_1.exists)(json, 'keyCollection') ? undefined : json['keyCollection'],
        'phoneNumber': !(0, runtime_1.exists)(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'email': !(0, runtime_1.exists)(json, 'email') ? undefined : json['email'],
    };
}
function BuildingDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'description': value.description,
        'brand': value.brand,
        'photos': value.photos,
        'latlng': (0, LatLng_1.LatLngToJSON)(value.latlng),
        'keyCollection': value.keyCollection,
        'phoneNumber': value.phoneNumber,
        'email': value.email,
    };
}
