"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Amenity = void 0;
exports.AmenityFromJSON = AmenityFromJSON;
exports.AmenityFromJSONTyped = AmenityFromJSONTyped;
exports.AmenityToJSON = AmenityToJSON;
/**
 *
 * @export
 */
exports.Amenity = {
    Parking: 'parking',
    Gym: 'gym',
    Wifi: 'wifi',
    _24HourFrontDesk: '24HourFrontDesk',
    AccessiblilityHearing: 'accessiblilityHearing',
    AccessibillityMobility: 'accessibillityMobility',
    AdultOnly: 'adultOnly',
    BusinessCentre: 'businessCentre',
    CashMachine: 'cashMachine',
    ChildcareService: 'childcareService',
    Concierge: 'concierge',
    Laundry: 'laundry',
    Lounge: 'lounge',
    PetsAllowed: 'petsAllowed',
    Pool: 'pool',
    Restaurant: 'restaurant',
    RoomService: 'roomService',
    Spa: 'spa'
};
function AmenityFromJSON(json) {
    return AmenityFromJSONTyped(json, false);
}
function AmenityFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function AmenityToJSON(value) {
    return value;
}
